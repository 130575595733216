import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { parseISO, format, isSameDay, isYesterday } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@mui/styles";
import { green, grey } from "@mui/material/colors";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { IconButton, Tooltip } from "@mui/material";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { v4 as uuidv4 } from "uuid";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import AndroidIcon from "@mui/icons-material/Android";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TicketMessagesDialog from "../TicketMessagesDialog";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    textAlign: "right",
    position: "absolute", // Adicione esta linha
    right: 0, // Adicione esta linha
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: 20,
    maxWidth: "60%", // Ajuste o tamanho da última mensagem
  },

  newMessagesCount: {
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },

  acceptButton: {
    position: "absolute",
    right: "108px",
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  ticketInfo: {
    textAlign: "right",
  },

  ticketInfo1: {
    position: "relative",
    top: 40,
    right: 0
  },
  Radiusdot: {

    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3,
      fontSize: 10,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },
  },
  presence: {
    color: theme.mode === 'light' ? "green" : "lightgreen",
    fontWeight: "bold",
  }
}));

const TicketListItemCustom = ({ ticket }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [ticketUser, setTicketUser] = useState(null);
  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const [openProfilePicDialog, setOpenProfilePicDialog] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useContext(AuthContext);
  const { profile } = user;
  const history = useNavigate(); // Substituir useHistory por useNavigate

  useEffect(() => {
    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user.name);
    }

    return () => {
      isMounted.current = false;
    };
  }, [ticket]);

  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    navigate(`/tickets/${ticket.uuid}`);
  };

  const handleSelectTicket = (ticket) => {
    if (!ticket) return; // Adiciona verificação
    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
  };

  const handleOpenProfilePicDialog = () => {
    setOpenProfilePicDialog(true);
  };

  const handleCloseProfilePicDialog = () => {
    setOpenProfilePicDialog(false);
  };

  const handleCloseTicket = async (id) => {
    setLoading(true);
    try {
      // Obtenha o número de telefone do ticket.contact
      const phoneNumber = ticket.contact?.number;

      if (phoneNumber) {
        // Substitua (number) pelo número de telefone no URL do webhook
        const webhookUrl = `https://n8n.tinex.app/webhook/imperatriz/changestatus?remoteJid=${phoneNumber}&status=closed`;

        // Função assíncrona para chamar o webhook
        const callWebhook = async () => {
          try {
            // Chame o webhook usando fetch
            await fetch(webhookUrl, {
              method: "GET", // Ou "POST" se necessário
            });

            console.log("Webhook chamado com sucesso");
          } catch (error) {
            console.error("Erro ao chamar o webhook:", error);
          }
        };

        // Chame a função assíncrona para chamar o webhook
        await callWebhook();
      }

      // Em seguida, atualize o status do ticket para "aberto" no seu sistema
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history(`/tickets/${ticket.uuid}`); // Substituir push por navigate
  };

  const renderLastMessageTime = () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        {ticket.status === "open" && ticket.unreadMessages > 0 && (
          <Badge
            className={classes.newMessagesCount}
            badgeContent={ticket.unreadMessages}
            classes={{
              badge: classes.badgeStyle,
            }}
            style={{ marginRight: 60 }}
          />
        )}
        <Typography
          className={classes.lastMessageTime}
          component="span"
          variant="body2"
          color="textSecondary"
        >
          {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
            <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
          ) : isYesterday(parseISO(ticket.updatedAt)) ? (
            <>ontem</>
          ) : (
            <>{format(parseISO(ticket.updatedAt), "dd/MM")}</>
          )}
        </Typography>
      </Box>
    );
  };

  const renderTicketInfo = () => {
    if (ticketUser && ticket.status !== "pending") {
      return (
        <Box display="flex" alignItems="center">
          {((profile === "admin" || profile === "user") && ticket.status !== 'closed') && (
            <Tooltip title="Espiar Conversa">
              <VisibilityIcon
                onClick={() => setOpenTicketMessageDialog(true)}
                fontSize="small"
                style={{
                  color: grey[700],
                  cursor: "pointer",
                  marginRight: 5,
                }}
              />
            </Tooltip>
          )}
          {ticket.isBot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}
          {ticket.channel === "whatsapp" && (
            <Tooltip title={`Conversa no Whatsapp`}>
              <WhatsAppIcon fontSize="small" style={{ color: grey[700] }} />
            </Tooltip>
          )}
          {ticket.channel === "instagram" && (
            <Tooltip title={`Conversa no Instagram`}>
              <InstagramIcon fontSize="small" style={{ color: grey[700] }} />
            </Tooltip>
          )}
          {ticket.channel === "facebook" && (
            <Tooltip title={`Conversa no Facebook`}>
              <FacebookIcon fontSize="small" style={{ color: grey[700] }} />
            </Tooltip>
          )}
          {ticket.status === "open" && (
            <>
            <Tooltip title={`Conversa atribuída à ${ticketUser.split(" ")[0]}`}>

              <Chip
                label={ticketUser?.split(" ")[0]}
                size="small"
                style={{ marginLeft: 5 }}
              />
              </Tooltip>

            </>
          )}
        </Box>
      );
    } else {
      return (
        <Box display="flex" alignItems="center">
          {ticket.isBot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}
          {((profile === "admin" || profile === "user") && ticket.status !== 'closed') && (
            <Tooltip title="Espiar Conversa">
              <VisibilityIcon
                onClick={() => setOpenTicketMessageDialog(true)}
                fontSize="small"
                style={{
                  color: grey[700],
                  cursor: "pointer",
                  marginRight: 5,
                }}
              />
            </Tooltip>
          )}
          {ticket.status === "open" && (
            <Badge
              className={classes.badgeStyle}
              badgeContent={ticket.unreadMessages}
              style={{ marginLeft: 8 }}
            />
          )}
        </Box>
      );
    }
  };

  return (
    <React.Fragment key={ticket.id}>
      <TicketMessagesDialog
        open={openTicketMessageDialog}
        handleClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket.id}
      ></TicketMessagesDialog>
      <Dialog
        open={openProfilePicDialog}
        onClose={handleCloseProfilePicDialog}
        PaperProps={{
          style: {
            minWidth: 400,
            minHeight: 400,
            overflow: 'hidden',
            borderRadius: 10,
            transition: 'transform 0.3s ease-in-out'
          }
        }} // Ajuste para evitar scroll e bordas arredondadas
      >
        <div style={{ position: 'relative', height: '100%' }}>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px 16px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            zIndex: 1
          }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={ticket?.contact?.profilePicUrl} alt="Imagem do contato" style={{ width: 40, height: 40, marginRight: 8 }} />
              <Typography variant="h6">{ticket.contact.name}</Typography>
            </div>
            <IconButton onClick={handleCloseProfilePicDialog} size="large" style={{ color: 'white' }}>
              <CloseIcon />
            </IconButton>
          </div>
          {ticket?.contact?.profilePicUrl && !ticket.contact.profilePicUrl.includes("nopicture") ? (
            <img src={ticket?.contact?.profilePicUrl} alt="Imagem do contato" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          ) : (
            <Typography variant="h6" style={{ textAlign: 'center', marginTop: '50%' }}>
              Sem foto de perfil ou a foto está privada
            </Typography>
          )}
        </div>
      </Dialog>
      <ListItem
        dense
        button
        onClick={(e) => {
          if (ticket.status === "pending") return;
          handleSelectTicket(ticket);
        }}
        selected={ticketId && +ticketId === ticket.id}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        <Tooltip
          arrow
          placement="right"
          title={ticket.queue?.name || "Sem fila"}
        >
          <span
            style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
            className={classes.ticketQueueColor}
          ></span>
        </Tooltip>
        <ListItemAvatar>
          <Avatar src={ticket?.contact?.profilePicUrl} onClick={handleOpenProfilePicDialog} style={{ cursor: 'pointer' }} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <span className={classes.contactNameWrapper}>
              <Typography
                noWrap
                component="span"
                variant="body2"
                color="textPrimary"
              >
                {ticket.contact.name}
              </Typography>
            </span>
          }
          secondary={
            <span className={classes.contactNameWrapper}>
              <Typography
                className={classes.contactLastMessage}
                noWrap
                component="span"
                variant="body2"
                color="textSecondary"
              >
                {ticket.contact?.presence && ticket.contact.presence !== "available" ? (
                  <span className={classes.presence}>
                    {i18n.t(`presence.${ticket.contact.presence}`)}
                  </span>
                ) : (
                  <>
                    {ticket.lastMessage?.includes('data:image/png;base64') ? <MarkdownWrapper> Localização</MarkdownWrapper> : <MarkdownWrapper>{ticket.lastMessage.startsWith('BEGIN:VCARD') ? "🪪" : ticket.lastMessage}</MarkdownWrapper>}
                  </>
                )}
              </Typography>

              {
              /*
             ticket.status === "open" && (
                <Badge
                  vertical="center"
                  className={classes.newMessagesCount}
                  badgeContent={ticket.unreadMessages}
                  classes={{
                    badge: classes.badgeStyle,
                  }}
                />
              )
                */}
            </span>
          }
        />


        {

          ticket.status === "pending" && (
            <ButtonWithSpinner
              color="primary"
              variant="contained"
              className={classes.acceptButton}
              size="small"
              loading={loading}
              onClick={(e) => handleAcepptTicket(ticket.id)}
            >
              {i18n.t("ticketsList.buttons.accept")}
            </ButtonWithSpinner>
          )}
        {ticket.status === "pending" && (
          <IconButton onClick={e => handleCloseTicket(ticket.id)} color="primary">
            <Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
              <CheckCircleIcon />
            </Tooltip>
          </IconButton>
        )}

        <ListItemSecondaryAction>
          {ticket.status === "closed" && (
            <Badge
              className={classes.closedBadge}
              badgeContent={"closed"}
              color="primary"
            />
          )}
          {ticket.lastMessage && (
            <>
              <Box className={classes.ticketInfo}>
                {renderLastMessageTime()}
              </Box>
              <Box className={classes.ticketInfo} style={{ marginTop: 15 }}>
                {renderTicketInfo()}
              </Box>
            </>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
};

export default TicketListItemCustom;
