import React, { useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { IconButton, Chip } from "@mui/material";
import { MoreVert, Replay } from "@mui/icons-material";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UndoRoundedIcon from '@mui/icons-material/UndoRounded';
import Tooltip from '@mui/material/Tooltip';
import { green } from '@mui/material/colors';

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
}));

const TicketActionButtonsCustom = ({ ticket }) => {
	const classes = useStyles();
	// const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);
	const { setCurrentTicket } = useContext(TicketsContext);

	const customTheme = createTheme({
		palette: {
		  	primary: green,
		}
	});

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		setLoading(true);
		let estado = "opened";
			
		if (status === "open") {
			estado = "paused";
		} else if (status === "closed") {
			estado = "closed";
		}
		console.log(estado);

		try {
			await api.put(`/tickets/${ticket.id}`, {
				status: status,
				userId: userId || null,
			});

			setLoading(false);
			if (status === "open") {
				setCurrentTicket({ ...ticket, code: "#open" });
			} else {
				setCurrentTicket({ id: null, code: null });
				//	navigate("/tickets", { replace: true }); // Use replace para garantir que a navegação recarregue a página
				//	if (status === "closed") {
				//		window.location.reload(); // Recarrega toda a aplicação (ToDo: Corrigir isso)
				//	}
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		(<div className={classes.actionButtons}>
			{ticket.status === "closed" && (
				<>
					<Chip
						loading={loading}
						icon={<Replay />}
						size="small"
						label={i18n.t("messagesList.header.buttons.reopen")}
						onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
					/>
				</>
			)}
			{ticket.status === "open" && (
				<>
					<Tooltip title={i18n.t("messagesList.header.buttons.return")}>
						<IconButton onClick={e => handleUpdateTicketStatus(e, "pending", null)} size="large">
							<UndoRoundedIcon />
						</IconButton>
					</Tooltip>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={customTheme}>
							<Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
								<IconButton
									onClick={e => handleUpdateTicketStatus(e, "closed", user?.id)}
									color="primary"
									size="large">
									<CheckCircleIcon />
								</IconButton>
							</Tooltip>
						</ThemeProvider>
					</StyledEngineProvider>
					<IconButton onClick={handleOpenTicketOptionsMenu} size="large">
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
		</div>)
	);
};

export default TicketActionButtonsCustom;
